.main{
  display: flex;
  flex-flow: row nowrap;
}
.admins{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* flex-grow: 1; */
  flex: 1 1 auto;
  min-width: 90%;
  padding: 0.5rem;
  margin: 1rem 0.25rem;
  background: rgba(3, 3, 3, 0.3);
  border-radius: 1.25rem;
  justify-content: center;
  align-items: center;
}
.admins .adminRow{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 auto;
  min-width: 75%;
  padding: 0.5rem;
  margin: 1rem 0.25rem;
  background: rgba(3, 3, 3, 0.3);
  border-radius: 1.25rem;
}
.adminRow .adminHeader{
  font-size: 1.2rem; /* h5 */
}
.adminRow .adminDetail{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  /* justify-content: space-around; */
}

.adminDetail .avtar{
  flex: 4 4 2rem;

}
.adminDetail .qualification{
  flex: 7 7 2rem;
}


.admins .text{
  text-align: justify;
  text-indent: 2rem;
  background: rgba(8, 8, 8, 0.6);
  padding: 0.5rem 1rem;
  border-radius: 0.813rem;
  font-size: 1.2rem;
}
.text p{
  margin-bottom: 1rem;
}
.contactUs{
  min-height: 65vh;
}
  