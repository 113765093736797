/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

:root{
  --body-color: rgb(255, 196, 0);
  --body-bg: #111; 
  --gray_1a  : #1a1a1a; 
  --gray_2a  : #2a2a2a;
  --gray_3_3 : rgba(3,3,3,0.3);
  --gray_3   : #333;
  --gray_7   : #777;
  --gray_9   : #999;
  --gray_d   : #ddd;
  --gray_d1  : #d1d1d1;
}

body {
  background: #111;
  color:green !important;
}

.updates {
  border-radius:10px;
  text-align:justify;
  cursor:pointer; 
  background:var(--gray_1a);
  padding:10px; 
  margin-bottom:8px; 
  height:20rem;
}
.updates marque{
  height:15rem;
}
.updates:hover{
  background:var(--gray_2a);
}

 .qualification{
    border-radius:10px;
    text-align:justify;
    cursor:pointer; 
    background:var(--gray_1a);
    padding:10px;
    margin-bottom:4px;
 }

.bg1{
  background:var(--gray_3_3); 
  border-radius:18px;
}
.bg2{
  background:rgba(8,8,8,0.6); 
  border-radius:12px;
}
.about_col{
  background:rgba(8,8,8,0.6); 
  border-radius:12px;
}
.about_col h3{
  margin-bottom: 1.5rem;
}
.admin_row{
  background:rgba(3,3,3,0.3); 
  border-radius:18px;
}
.admin_row  div b{
  color:#ddd;
}
.admin_row  div img{
  margin-bottom: 1rem;
}
.admin_row  div.qualification{
  padding-left: 1rem;
}
.confirm_del{
  min-height:34rem; 
  margin-bottom:2rem; 
  color:#d1d1d1;
}
.confirm_del div div{
  min-width:60rem; background:rgba(8,8,8,0.7); border-radius:12px;
}

.contact_row{
  background:rgba(3,3,3,0.3); 
  border-radius:18px;
}
.contact_row div img{
  margin-bottom:18px;
}
.contact_row div.qualification{
  padding-left:26px; 
  padding-right:26px; 
}
.contact_row div.qualification hr{
  height:1px; 
  background:#999;
}
.contact_row div.qualification  a{
  color:#AAA;
}
.container, .container-fluid{
  min-height    : 34rem; 
  margin-bottom : 2rem; 
  color         : var(--gray_d1);
}
.container div.row .form_col, .container-fluid div.row .form_col{
  min-width:60rem; 
  background:rgba(8,8,8,0.7); 
  border-radius:12px;
}
div.col hr{
  height:1px; background:#fff;
}
.download_col{
  margin-bottom:4rem; margin-right:15px;
}
.download_col  div.row{
  background:rgba(2,2,2,0.7); 
  border-radius:8px; 
  padding-bottom:5px; 
  padding-top:5px; 
  margin-bottom:5px;
}
.download_col div.row div.col{
  font-size:10px;
}
.download_col div.col-10 a{
  font-size:0.9rem; color:#999;
}
.members_col{
  min-width:30rem; background:rgba(8,8,8,0.7); border-radius:12px;
}
.manage_col{
  min-width:60rem; background:rgba(8,8,8,0.7); 
  border-radius:12px;
}
.carousel-caption{
  background:rgba(2,2,2,0.6); border-radius:12px; width:20rem; height:4.5rem; padding-top:2px;
}
.min_h_20{
  min-height:20rem;
}
.index_content{
  background:rgba(8,8,8,0.6); 
  padding-top:8px; 
  padding-bottom:8px;
  border-radius:12px;
}
body, div, a, b, table tr, h1, h2, li{
  color:  var(--body-color) !important;
  font-family: 'ZCOOL XiaoWei', serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.96rem;
}
.App{
  min-height: 82vh;
}
td, th{
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.text-justify{
  margin: 0;
  text-indent: 2rem;
  text-align: justify;
}