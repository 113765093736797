.main{
  display: flex;
  flex-flow: row nowrap;
}

.home{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 85vh;
  align-items: center;
  padding: 1rem;
}

.home .carousel{
  min-height: 20rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

.home .carousel div{
  min-height: 20rem;
}

.home .text{
  text-align: justify;
  text-indent: 2rem;
  background: rgba(8, 8, 8, 0.6);
  padding: 0.5rem 1rem;
  border-radius: 0.813rem;
  font-size: 1.2rem;
}
.text p{
  margin-bottom: 1rem;
}
