.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.header .headerText{
  flex: 0 1 auto;
}
.header .logo{
  flex: 0 1 auto;
}

.header .navs{
  flex: 1 1 auto;
}

.header nav{
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  font-size: 1.2rem;
  padding: 1rem;
  justify-content: flex-end;
  text-transform: uppercase;
}
.header nav a{
  text-decoration: none;
}

.footer{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0.5rem;
  font-weight: bold;
}

.aside{
  display: flex;
  flex: 0 1 auto;
  padding: 1rem;
  min-width: 13rem;
}

.aside .slidingElement{
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 1rem;
}

.slidingElement a {
  display:block; 
  padding:8px 18px; 
  margin-bottom:5px;  
  color:var(--gray_9); 
  background:var(--gray_3); 
  border:1px solid var(--gray_1a);
  text-decoration:none;
}

.slidingElement a:hover{
  color:var(--gray_d);
  padding-left: 28px;
  margin-right: -18px;
}